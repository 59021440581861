var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative pb-1"},[(_vm.loading)?_c('div',{staticClass:"loader-overlay"},[_c('b-spinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"variant":"primary"}})],1):_vm._e(),_c('b-card',[_c('form',{ref:"chatForm",staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.submit}},[_c('validation-observer',{ref:"chatInfo",attrs:{"tag":"form"}},[_c('b-row',[_vm._l((_vm.languages),function(language){return _c('b-col',{key:language.model + 'title',attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":language.label + ' Title',"label-for":language.label + ' Title'}},[_c('validation-provider',{attrs:{"name":language.label + ' Title',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":language.label + ' Title'},model:{value:(_vm.value['title' + language.model]),callback:function ($$v) {_vm.$set(_vm.value, 'title' + language.model, $$v)},expression:"value['title' + language.model]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),_vm._l((_vm.languages),function(language){return _c('b-col',{key:language.model + 'name',attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":language.label + ' name of the specialist',"label-for":language.label + ' Name'}},[_c('validation-provider',{attrs:{"name":language.label + ' Name',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":language.label + ' Name'},model:{value:(_vm.value['name' + language.model]),callback:function ($$v) {_vm.$set(_vm.value, 'name' + language.model, $$v)},expression:"value['name' + language.model]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"Tags","label":'Tags'}},[_c('validation-provider',{attrs:{"name":'Tags',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"Tags","multiple":"","placeholder":'Tags',"label":"name","loading":_vm.topicLoader,"options":_vm.topicsList,"reduce":function (option) { return option._id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.value.topics),callback:function ($$v) {_vm.$set(_vm.value, "topics", $$v)},expression:"value.topics"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"language","label":'Language'}},[_c('validation-provider',{attrs:{"name":'Language',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"language","multiple":"","placeholder":'Language',"label":"name","loading":_vm.languageLoader,"options":_vm.languagesList,"reduce":function (option) { return option._id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.value.languages),callback:function ($$v) {_vm.$set(_vm.value, "languages", $$v)},expression:"value.languages"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_vm._l((_vm.languages),function(language){return _c('b-col',{key:language.model + 'description',attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":language.label + ' Description',"label-for":language.label + ' Description'}},[_c('validation-provider',{attrs:{"name":language.label + ' Description',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":language.label + ' Description',"rows":"3"},model:{value:(_vm.value['description' + language.model]),callback:function ($$v) {_vm.$set(_vm.value, 'description' + language.model, $$v)},expression:"value['description' + language.model]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),_vm._l((_vm.languages),function(language){return _c('b-col',{key:language.model + 'rules',attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":language.label + ' Rules',"label-for":language.label + ' Rules'}},[_c('validation-provider',{attrs:{"name":language.label + ' Rules',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":language.label + ' Rules',"rows":"3"},model:{value:(_vm.value['rules' + language.model]),callback:function ($$v) {_vm.$set(_vm.value, 'rules' + language.model, $$v)},expression:"value['rules' + language.model]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),_c('div',{staticClass:"col-6 d-flex mb-2"},[_c('b-form-group',{attrs:{"label":"Chat Image","label-for":"Chat Image"}},[_c('div',{staticClass:"profile-img",on:{"click":function($event){return _vm.$refs.myFile.click()}}},[_c('input',{ref:"myFile",staticClass:"hidden",attrs:{"type":"file"},on:{"change":_vm.onFileChange}}),(!_vm.value._id)?_c('div',[(_vm.media1)?_c('img',{staticClass:"profile-img",attrs:{"src":_vm.media1 + (_vm.isPreview ? '' : '?' + Math.random()),"alt":"profile image"}}):_c('img',{staticClass:"profile-img",attrs:{"src":require('@/assets/images/pages/upload2.png'),"alt":""}})]):_c('div',[(!_vm.media1)?_c('img',{staticClass:"profile-img",attrs:{"src":_vm.value.media.path +
                      (_vm.isPreview ? '' : '?' + Math.random()),"alt":"profile image"}}):_c('img',{staticClass:"profile-img",attrs:{"src":_vm.media1 + (_vm.isPreview ? '' : '?' + Math.random()),"alt":"profile image"}}),_c('label',[_vm._v("Change image")])])])])],1)],2)],1),_c('b-button',{staticClass:"float-right",attrs:{"id":"show-btn","variant":"primary","disabled":_vm.loading},on:{"click":_vm.submit}},[_vm._v("Save")]),_c('b-button',{staticClass:"float-right mr-1",attrs:{"id":"cancel-btn","variant":"primary"},on:{"click":_vm.close}},[_vm._v("Cancel")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }