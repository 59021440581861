import CrudService from "@/services/shared/http/CrudService";

export default class ChatService extends CrudService {
  constructor(params = {}) {
    super("conversation", params);
  }
  index(params = {}) {
    return this.api.GET("conversation/get-to-admin", params)
  }
  makeChatOfweek(data, params = {}) {
    return this.api.POST('conversation/makeOfWeek', data, params)
  }
}
