<template>
  <div class="position-relative pb-1">
    <div v-if="loading" class="loader-overlay">
      <b-spinner style="width: 4rem; height: 4rem" variant="primary" />
    </div>
    <b-card>
      <form
        ref="chatForm"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="submit"
      >
        <validation-observer ref="chatInfo" tag="form">
          <b-row>
            <b-col
              v-for="language in languages"
              :key="language.model + 'title'"
              md="6"
            >
              <b-form-group
                :label="language.label + ' Title'"
                :label-for="language.label + ' Title'"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="language.label + ' Title'"
                  rules="required"
                >
                  <b-form-input
                    v-model="value['title' + language.model]"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="language.label + ' Title'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-for="language in languages"
              :key="language.model + 'name'"
              md="6"
            >
              <b-form-group
                :label="language.label + ' name of the specialist'"
                :label-for="language.label + ' Name'"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="language.label + ' Name'"
                  rules="required"
                >
                  <b-form-input
                    v-model="value['name' + language.model]"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="language.label + ' Name'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="Tags" :label="'Tags'">
                <validation-provider
                  #default="{ errors }"
                  :name="'Tags'"
                  rules="required"
                >
                  <v-select
                    id="Tags"
                    v-model="value.topics"
                    multiple
                    :placeholder="'Tags'"
                    label="name"
                    :loading="topicLoader"
                    :options="topicsList"
                    :reduce="(option) => option._id"
                    :class="
                      errors.length > 0 ? 'border-danger-for-v-select' : ''
                    "
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="language" :label="'Language'">
                <validation-provider
                  #default="{ errors }"
                  :name="'Language'"
                  rules="required"
                >
                  <v-select
                    id="language"
                    v-model="value.languages"
                    multiple
                    :placeholder="'Language'"
                    label="name"
                    :loading="languageLoader"
                    :options="languagesList"
                    :reduce="(option) => option._id"
                    :class="
                      errors.length > 0 ? 'border-danger-for-v-select' : ''
                    "
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-for="language in languages"
              :key="language.model + 'description'"
              md="6"
            >
              <b-form-group
                :label="language.label + ' Description'"
                :label-for="language.label + ' Description'"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="language.label + ' Description'"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="value['description' + language.model]"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="language.label + ' Description'"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-for="language in languages"
              :key="language.model + 'rules'"
              md="6"
            >
              <b-form-group
                :label="language.label + ' Rules'"
                :label-for="language.label + ' Rules'"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="language.label + ' Rules'"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="value['rules' + language.model]"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="language.label + ' Rules'"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <div class="col-6 d-flex mb-2">
              <b-form-group label="Chat Image" label-for="Chat Image">
                <div @click="$refs.myFile.click()" class="profile-img">
                  <input
                    ref="myFile"
                    type="file"
                    class="hidden"
                    @change="onFileChange"
                  />
                  <div v-if="!value._id">
                    <img
                      v-if="media1"
                      class="profile-img"
                      :src="media1 + (isPreview ? '' : '?' + Math.random())"
                      alt="profile image"
                    />
                    <img
                      v-else
                      class="profile-img"
                      :src="require('@/assets/images/pages/upload2.png')"
                      alt=""
                    />
                  </div>
                  <div v-else>
                    <img
                      v-if="!media1"
                      class="profile-img"
                      :src="
                        value.media.path +
                        (isPreview ? '' : '?' + Math.random())
                      "
                      alt="profile image"
                    />
                    <img
                      v-else
                      class="profile-img"
                      :src="media1 + (isPreview ? '' : '?' + Math.random())"
                      alt="profile image"
                    />
                    <label>Change image</label>
                  </div>
                </div>
              </b-form-group>
            </div>
          </b-row>
        </validation-observer>
        <b-button
          id="show-btn"
          @click="submit"
          class="float-right"
          variant="primary"
          :disabled="loading"
          >Save</b-button
        >

        <b-button
          id="cancel-btn"
          @click="close"
          class="float-right mr-1"
          variant="primary"
          >Cancel</b-button
        >
      </form>
    </b-card>
  </div>
</template>
  
  <script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import {
  BFormGroup,
  BFormInput,
  BCard,
  BRow,
  BFormInvalidFeedback,
  BCol,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import SharedService from "@/services/SharedService.js";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    TabContent,
    vSelect,
    BFormInvalidFeedback,
    BSpinner,
    BCard,
    BInputGroupAppend,
    BFormCheckbox,
  },
  props: ["value", "action"],
  data() {
    return {
      sharedService: new SharedService(),
      loading: false,
      formData: null,
      required,
      languageLoader: true,
      topicLoader: true,
      languagesList: [],
      topicsList: [],
      isPreview: false,
      media: null,
      media1: null,
      languages: [
        {
          label: 'English',
          model: '',
        },
        {
          label: 'France',
          model: 'Fr',
        },
      ],
    }
  },
  mounted() {
    this.getLanguagesList();
    this.getTopicsList();
  },
  watch: {},
  methods: {
    submit() {
      this.$refs.chatInfo.validate().then((success) => {
        if (success) {
          this.loading = true;
          const formData = new FormData();

          this.languages.forEach(({ model }) => {
            formData.append(`title${model}`, this.value['title' + model]);
            formData.append(`name${model}`, this.value['name' + model]);
            formData.append(`description${model}`, this.value['description' + model]);
            formData.append(`rules${model}`, this.value['rules' + model]);
          });

          formData.append("topics", this.value.topics);
          formData.append("languages", this.value.languages);
          if(this.media != null) formData.append("media", this.media);
          this.$emit("formSubmitted", formData);
          } else {
        }
      });
    },
    close() {
      this.$router.push("/chats");
    },
    getLanguagesList() {
      this.languageLoader = true;
      this.sharedService.getLanguagesList().then((res) => {
        this.languageLoader = false;
        this.languagesList = res;
      });
    },
    getTopicsList() {
      this.topicLoader = true;
      this.sharedService.getTopicsList().then((res) => {
        this.topicLoader = false;
        this.topicsList = res;
      });
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.media = e.target.files[0];
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.media1 = e.target.result;
        this.isPreview = true;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>
<style>
.profile-img {
  width: 140px;
  height: 140px;
  border-radius: 1.357rem;
  object-fit: cover;
  cursor: pointer;
  border: groove;
}
</style>
