<template>
  <div class="">
    <ChatForm ref="ChatForm" v-model="formData" @formSubmitted="storeChat"/>
  </div>
</template>

<script>
import ChatForm from "@/views/components/chat/ChatForm";
import ChatService from "@/services/ChatService";

export default {
  name: "CreateChat",
  components: {
    ChatForm
  },
  data() {
    return {
      ChatService: new ChatService(),
      formData: {
        // what key i have
      },
    }
  },
  methods: {
    storeChat(e) {
      this.ChatService.create(e).then(res => {
        this.$router.push('/chats')
        // window.location.href = 'home'
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>
</style>
